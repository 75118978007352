import request from '@/common/request'

export function changeLive (total, live) {
  return request({
    url: '/rcv/t',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: { total: total, live: live }
  })
}
