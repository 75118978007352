<template>
  <div class="c_dialog">
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      title="修改基数"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">

        <el-form-item label="总数" prop="total">
          <el-input
            v-model="form.total"
            placeholder="总数"
            maxlength="5"
          />
        </el-form-item>

        <el-form-item label="在线人数" prop="live">
          <el-input
            v-model="form.live"
            placeholder="输入在线人数"
            maxlength="5"
          />
        </el-form-item>
        <el-divider />
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')"> 保存修改 </el-button>
          <el-button @click="resetForm('form')">重置</el-button>
          <el-button @click="dismiss">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  changeLive
} from "@/api/live";
import hash from "js-hash-code";

export default {
  components: {},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      formHash: -1,
      form: {
        total: 1316,
        live: 298
      },

      rules: {
        total: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: async (r, v, c, s) => {
              if (!s.total || s.total < 1) {
                return c(new Error("总数不能小于1！"));
              }

              c();
            },
          },
        ],
        live: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: async (r, v, c, s) => {
              if (!s.live || s.live < 0) {
                return c(new Error("在线人数不能小于0！"));
              }

              c();
            },
          },
        ],
      },
    };
  },
  methods: {
    async show() {
      this.dialogVisible = true;


      this.form.total = this.$store.getters.am_total['total']
      this.form.live = this.$store.getters.am_total['live']
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if(!valid) return false

        changeLive(this.form.total, this.form.live).then((data) => {
          if (data.status !== -1) {
            this.$message({
              type: "error",
              message: `保存数据失败，原因：${data.msg}。`,
            });

            return;
          }

          this.$message({
            type: "success",
            message: `保存成功！`,
          })

          const t = {'total': this.form.total, 'live': this.form.live}

          this.$store.dispatch('update_total', t)
          this.dismiss()
        })
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {
        total: 0,
        live: 0
      }
      this.formHash = hash(this.form)
    },
    dismiss() {
      this.dialogVisible = false;
      this.resetForm('form')
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.resetForm('form')
      done()
    },
  },
};
</script>
<style lang="scss">
.c_dialog {
  .el-dialog {
    border-radius: 20px !important;
    background-color: rgba(25, 25, 25, 0.98);
    box-shadow: 0 2px 6px rgb(250 250 250 / 20%);
  }
  .el-divider__text {
    color: white;
    border-radius: 10px !important;
    background-color: rgba(25, 25, 25, 0.98);
  }
}
</style>
