<template>
  <div class="app-container">
    <div class="filter-container" >
      <el-input
        v-model="listQuery.id"
        placeholder="用户ID"
        style="width: 200px;"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />
      <span style="padding-left: 10px" />
      <el-input
        v-model="listQuery.name"
        placeholder="用户名, 符号%模糊查询"
        style="width: 200px;"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />
      <span style="padding-left: 10px" />
      <el-select
        v-model="listQuery.state"
        placeholder="用户状态"
        clearable
        style="width: 90px"
        class="filter-item"
      >
        <el-option v-for="item in stateOptions" :key="item.key" :label="item.label" :value="item.key" />
      </el-select>

      <span style="padding-left: 10px" />
      <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button
        class="filter-item"
        style="margin-left: 10px;"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
      >
        添加新用户
      </el-button>
      <el-button
          class="filter-item"
          style="margin-left: 10px;"
          type="primary"
          icon="el-icon-edit"
          @click="setupCardinal"
        >
          设置基数
        </el-button>
    </div>
    <el-divider style="margin-bottom:10px"/>
    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
      element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(10, 10, 10, 0.7)"
      @sort-change="sortChange"
    >
      <!--el-table-column :label="$t('table.id')" prop="id" sortable="custom" align="left" width="80" :class-name="getSortClass('id')">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column-->
      <el-table-column
        type="index"
        width="50"
      />
      <el-table-column label="用户ID" min-width="190px">
        <template slot-scope="{row}">
          <router-link :to="'/sys/user/edit/'+row.id" :replace="true">
            <el-link>{{ row.uid }}</el-link>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="名字" width="210px" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="电话" width="110px" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>

      <el-table-column label="角色" min-width="190px" align="center">
        <template slot-scope="{row}">
          <span style="">{{ row.roleNames | roleChangeFilter }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" class-name="status-col" width="80">
        <template slot-scope="{row}">
          <el-tag :type="row.status | statusFilter">
            {{ row.status | stateLabelFilter }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="160" class-name="small-padding fixed-width">
        <template slot-scope="{row}">
          <el-button type="primary" size="mini" @click="handleEdit(row.uid)">
            编辑
          </el-button>
          <span style="padding-left: 10px" />
          <el-button v-if="row.status!=-1" size="mini" type="default" @click="handleModifyStatus(row,'deleted')">
            删除
          </el-button>
          <el-button v-if="row.status==-1" size="mini" type="default" @click="handleModifyStatus(row,'enable')">
            启用
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.pageSize" @pagination="getList" />
  
      <user-editor ref="editor" @refresh="getList"/>
      <cardinal ref="cardinal"/>
  </div>
</template>

<script>
import { loadUsers, delUser } from '@/api/sys'
import waves from '@/directive/waves'
import Pagination from '@/components/Pagination'
import userEditor from './components/userEditor.vue'
import cardinal from './components/cardinal.vue'

export default {
  name: 'UserList',
  components: { Pagination, userEditor, cardinal },
  directives: { waves },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: 'success',
        '-1': 'danger'
      }
      return statusMap[status]
    },
    stateLabelFilter(state) {
      const labelMap = {
        0: '启用',
        '-1': '禁用'
      }
      return labelMap[state]
    },
    roleChangeFilter(rs) {
      return rs.join(',')
    }
  },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        pageSize: 20,
        state: undefined,
        name: undefined,
        id: '',
        sort: ['create_at DESC'],
      },
      stateOptions: [{ label: '启用', key: 0 }, { label: '禁用', key: '-1' }]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.listLoading = true

      const q = [], l = this.listQuery
      if(l['name']) {
        q.push(['name', 'like', l['name']])
      }
      if (l['id']) {
        q.push(['uid', 'like', l['id']])
      }
      if (l['state'] != undefined && l['state'] != '') {
        q.push(['status', '=', l['state']])
      }

      const rlt = await loadUsers(q,this.listQuery.sort, [this.listQuery.page, this.listQuery.pageSize])
      setTimeout(() => {
        this.listLoading = false
      }, 200)
      if(rlt.status != -1) {
        this.$message({type: 'error', message: rlt['msg']})
        return
      }


      this.list = rlt['content'].map(t => {
        t['roleNames'] = t.roles.map(r =>{
          return r =='admin'?'系统管理员': r == 'manager'?'管理':'查看'
        })
        return t
      })
      //console.log(this.list);
      this.total = rlt['total']
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleModifyStatus(row, status) {
      if (status === 'enable') {
        return
      }

      this.$confirm('数据删除后不能找回，确认是否删除？', '警告', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const { status, msg } = await delUser(row.id)
        if (status !== -1) {
          this.$message({
            message: `删除操作失败，错误原因：${msg}。`,
            type: 'error'
          })

          return
        }

        this.$message({
          message: '操作成功！',
          type: 'success'
        })

        this.getList()
      }).catch(() => {})

      // row.status = status
    },
    sortChange(data) {
      const { prop, order } = data
      if (prop === 'id') {
        this.sortByID(order)
      }
    },
    sortByID(order) {
      if (order === 'ascending') {
        this.listQuery.sort = 'id'
      } else {
        this.listQuery.sort = 'id DESC'
      }
      this.handleFilter()
    },
    handleEdit(uid) {
      this.$refs.editor.show(uid)
    },
    handleCreate() {
      this.$refs.editor.show()
    },
    handleDelete(row) {
      this.$notify({
        title: '成功',
        message: '删除成功',
        type: 'success',
        duration: 2000
      })
      const index = this.list.indexOf(row)
      this.list.splice(index, 1)
    },
    getSortClass: function(key) {
      const sort = this.listQuery.sort
      return sort === `${key}`
        ? 'ascending'
        : sort === `${key} DESC`
          ? 'descending'
          : ''
    },
    setupCardinal(){
this.$refs.cardinal.show()
    }
  }
}
</script>
<style scoped>
a{
  color:white
}
</style>
