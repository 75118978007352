<template>
  <div class="c_dialog">
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :title="navTitle"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <input type="hidden" name="id" :value="form.id" />
        <el-form-item label="用户ID" prop="id">
          <el-input
            v-model="form.uid"
            placeholder="输入用户ID"
            :disabled="isEdit"
          />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="输入密码"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="password_cfm">
          <el-input v-model="form.password_cfm" type="password" placeholder="确认密码" />
        </el-form-item>

        <el-form-item label="名字" prop="name">
          <el-input v-model="form.name" placeholder="输入名字" />
        </el-form-item>

        <el-form-item label="电话" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="输入电话"
            maxlength="20"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="form.status" />
        </el-form-item>

        <el-divider content-position="left">用户角色：</el-divider>
        <div>
          <el-checkbox-group v-model="form.roles">
            <el-checkbox v-for="role in roles" :key="role.id" :label="role.id"
              >{{ role.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-divider />
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')">{{
            submitBtnTitle
          }}</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
          <el-button @click="dismiss">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  createUser,
  updateUser,
  fetchUser as getUser,
  userExist,
} from "@/api/sys";
import hash from "js-hash-code";

export default {
  components: {},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      isEdit: false,
      typeOptions: [],
      regionOptions: [],
      tempRoute: {},
      formHash: -1,
      roles: [
        { id: "admin", name: "系统管理员" },
        { id: "manager", name: "管理" },
        { id: "viewer", name: "查看" },
      ],
      form: {
        name: "",
        id: "",
        password: "",
        phone: "",
        roles: [],
        status: false,
        password_cfm: ""
      },

      rules: {
        id: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: async (r, v, c, s) => {
              if (!this.isEdit) {
                const uid = s.id;
                if (!uid || uid.length < 6) {
                  return c(new Error("用户ID不能少于6个字符！"));
                }

                await userExist(uid).then((res) => {
                  const { content } = res;

                  if (content) {
                    c(new Error("用户ID已被使用，更换一个试试！"));
                  }
                });
              }
              c();
            },
          },
        ],
        password: [
          {
            required: false,
            trigger: "blur",
            validator: (r, v, c, s) => {
              const pa = s.password;

              if (this.isEdit && !pa) {
                c()
                return
              }
              
              if (!pa || pa.length < 6) {
                c(new Error("密码长度不能少于6个字符！"));
              }

              c();
            },
          },
        ],
        password_cfm: [
          {
            required: false,
            trigger: "blur",
            validator: (r, v, c, s) => {
              const pa = s.password_cfm;
              const pwd = this.form.password
              if(this.isEdit && !pa && !pwd){
                c()
                return
              }
              
              if (!pa || pa.length < 6) {
                c(new Error("密码长度不能少于6个字符！"));
              }
              
              if (pwd && pa && pa != pwd) {
                c(new Error("两次输入的密码不一致！"));
              }
              c();
            },
          },
        ],
        name: [
          { required: true, message: "不能为空！", trigger: "blur" },
          { min: 3, max: 256, message: "要求3～256个字符！", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    navTitle() {
      return this.isEdit ? "修改用户" : "新建用户";
    },
  },
  methods: {
    async show(uid) {
      this.dialogVisible = true;
      this.isEdit = false;
      this.submitBtnTitle = "新建";

      //modify
      if (uid) {
        this.isEdit = true;
        this.submitBtnTitle = "修改";
        this.loading = true;
        //load user
        await this.fetchUser(uid);
        this.loading = false;

        return
      }

      this.form.status = true
    },
    async fetchUser(id) {
      this.isEdit = true;

      const { status, content, msg } = await getUser(id);
      if (status != -1) {
        this.$message({
          type: "error",
          title: `加载用户(${id})错误，原因：${msg}。`,
        });
        return;
      }

      this.form.uid = content.uid;
      this.form.id = content.id;
      this.form.name = content.name;
      this.form.status = content.status == 0;
      this.form.phone = content.phone;
      this.form.roles = content.roles;

      this.formHash = hash(this.form);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _a = this.isEdit ? updateUser : createUser;

          this.formHash = hash(this.form);

          this.form.status = this.form.status ? 0 : -1;
          if (this.form.password) {
            this.form.pwd = hash(this.form.password, "sha1");
          }

          //console.log(this.form);
          _a(this.form).then((data) => {
            if (data.status !== -1) {
              this.$message({
                type: "error",
                title: `保存数据失败，原因：${data.msg}。`,
              });

              return;
            }

            if (this.isEdit) {
              this.$notify({
                message: "用户修改成功！",
                type: "success",
                duration: 6000,
              });
              this.formHash = hash(this.form)
              this.dismiss()
              return;
            }

            this.$confirm("用户已添加！", {
              type: "success",
              distinguishCancelAndClose: true,
              confirmButtonText: "是否继续添加新用户？",
              cancelButtonText: '否'
            })
              .then(() => {
                this.resetForm("form");
              })
              .catch((action) => {
                if (action === "cancel") {
                  this.formHash = hash(this.form)
                  this.dismiss()
                }
              });
          });
        
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {
        name: "",
        id: "",
        password: "",
        phone: "",
        roles: [],
        status: false,
        password_cfm: ""
      }
      this.formHash = hash(this.form)
    },
    dismiss() {
      this.dialogVisible = false;
      this.resetForm('form')
      this.$emit('refresh')
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.resetForm('form')
      done()
    },
  },
};
</script>
<style lang="scss">
.c_dialog {
  .el-dialog {
    border-radius: 20px !important;
    background-color: rgba(25, 25, 25, 0.98);
    box-shadow: 0 2px 6px rgb(250 250 250 / 20%);
  }
  .el-divider__text {
    color: white;
    border-radius: 10px !important;
    background-color: rgba(25, 25, 25, 0.98);
  }
}
</style>
